import React from 'react';
// import { Link } from 'react-router-dom';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { connect } from 'react-redux';

// Actions
import { gameActions } from '../../actions';

import Header from '../../components/Header';
import { DebriefMenu } from './debrief_menu';

const interventions = [
  { label: '4-Year College Scholarships', value: 14 },
  { label: 'After-School Programming', value: 5 },
  { label: 'Apprenticeship Program', value: 7 },
  { label: 'Business Start-up/Expansion for Job Creation', value: 18 },
  { label: 'Community College', value: 11 },
  { label: 'Company-Based Apprenticeships/Internships', value: 22 },
  { label: 'Criminal Justice Reentry Services', value: 15 },
  { label: 'Elementary/Middle School', value: 3 },
  { label: 'Foster Care Services', value: 13 },
  { label: 'GED/Bridge Program', value: 20 },
  { label: 'High School', value: 6 },
  { label: 'Hiring Incentives', value: 12 },
  { label: 'Housing Program or Support', value: 9 },
  { label: 'Income Support Program', value: 8 },
  { label: 'Internship/Apprenticeship Placement Program', value: 10 },
  { label: 'Mentoring Program', value: 1 },
  { label: 'Political Contributions', value: 17 },
  { label: 'Post-Secondary Training Program', value: 2 },
  { label: 'Preschool/Pre-K', value: 4 },
  { label: 'Public Advocacy/Lobbying', value: 16 },
  { label: 'Public Health Initiative', value: 23 },
  { label: 'Research/Evaluation', value: 19 },
  { label: 'Other', value: 21 },
];

const roles = [
  'Retired Entrepreneur',
  'Senior VP of Corporate Engagement',
  'Private Foundation President',
  'State Director of Commerce',
  'Investment Manager for Private Impact Investment Fund',
];

class DebriefIntervention extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: this.props.item || null,
      roleFilter: '',
    };

    // Bindings
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.dispatch(gameActions.fetchGames(this.props.match.params.id));
    }
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      item: newProps.item,
    });
  }

  render() {
    const { game } = this.props;
    const { roleFilter } = this.state;

    // Get players plays count
    const interventionTypes = [];
    let tacticsPerType = [];
    const investmentPerType = [];
    // Loop investments and get total tactics
    for (let i = 0; i < interventions.length; ++i) {
      // Add the category
      interventionTypes.push(interventions[i].label);
      // Count the strategies
      let thisTypeTactics = 0;
      let thisTypeInvestment = 0;
      for (let j = 0; j < game.data.players.length; ++j) {
        let tacs = game.data.players[j].tactics.filter((t) => {
          // console.log(t.intervention, interventions[i], t.author.email, t.author.role);
          return parseInt(t.intervention) == interventions[i].value;
        });

        if (roleFilter.length > 0)
          tacs = tacs.filter((t) => game.data.players[j].role == roleFilter);

        thisTypeTactics += tacs.length;
        thisTypeInvestment += tacs.reduce(function (acct, t) {
          return (
            acct +
            t.dros.reduce(function (acc, d) {
              return (
                acc +
                Number(d.y1) +
                Number(d.y2) +
                Number(d.y3) +
                Number(d.y4) +
                Number(d.y5) +
                Number(d.y6)
              );
            }, 0)
          );
        }, 0);

        if (j == game.data.players.length - 1) {
          tacticsPerType[i] = thisTypeTactics;
          investmentPerType[i] = thisTypeInvestment;
        }
      }
    }

    // console.log('tacticsPerType', tacticsPerType)

    const interventionTypesFinal = [];
    const investmentPerTypeFinal = [];
    tacticsPerType = tacticsPerType.filter((tacs, ti) => {
      if (tacs > 0) {
        interventionTypesFinal.push(interventionTypes[ti]);
        investmentPerTypeFinal.push(investmentPerType[ti]);
        return true;
      } else {
        return false;
      }
    });

    return (
      <div>
        <Header />

        <h2 className='mt-3 sec-title'>
          Intervention
          <small class='float-right'>
            <DebriefMenu
              game_id={game.data._id}
              location={this.props.location}
            />
          </small>
        </h2>

        <div className='row'>
          <div className='col-9'>
            <HighchartsReact
              containerProps={{ style: { height: '100%', paddingTop: '20px' } }}
              highcharts={Highcharts}
              options={{
                chart: { type: 'bar', height: 750 },
                credits: { enabled: false },
                title: { text: '' },
                xAxis: {
                  categories: interventionTypesFinal,
                  labels: { style: { fontSize: '14px' } },
                },
                yAxis: [
                  {
                    allowDecimals: false,
                    min: 0,
                    title: { text: 'Total Tactics' },
                    labels: { style: { fontSize: '14px' } },
                  },
                  {
                    allowDecimals: false,
                    min: 0,
                    title: { text: 'Total Investment ($)' },
                    opposite: true,
                  },
                ],
                legend: {
                  enabled: true,
                },
                series: [
                  {
                    name: 'Tactics',
                    data: tacticsPerType,
                    yAxis: 0,
                  },
                  {
                    name: 'Total Investment',
                    data: investmentPerTypeFinal,
                    yAxis: 1,
                    tooltip: {
                      pointFormatter: function () {
                        return (
                          '<b>$' +
                          Highcharts.numberFormat(this.y, 0, '.', ',') +
                          '</b>'
                        );
                      },
                    },
                  },
                ],
              }}
            />
          </div>
          <div className='col-3 pt-3'>
            <button
              onClick={() => this.setState({ roleFilter: '' })}
              className={`pt-3 pb-3 btn btn-block btn-${
                !roleFilter ? '' : 'outline-'
              }primary`}
              key={`role-all`}>
              Overall
            </button>
            {roles.map((r, ri) => {
              return (
                <button
                  onClick={() => this.setState({ roleFilter: r })}
                  className={`pt-3 pb-3 btn btn-block btn-${
                    r === roleFilter ? '' : 'outline-'
                  }primary`}
                  key={`role-${ri}`}>
                  {r}
                </button>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { player, game } = state;
  return {
    game,
    player,
  };
}

export default connect(mapStateToProps)(DebriefIntervention);
