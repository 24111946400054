import React from 'react';
// import { Link } from 'react-router-dom';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { connect } from 'react-redux';

// Actions
import { gameActions } from '../../actions';

import Header from '../../components/Header';
import { DebriefMenu } from './debrief_menu';

Highcharts.setOptions({
  lang: { decimalPoint: '.', thousandsSep: ',' },
});

const investments = [
  { label: 'Equity Investment', value: 1 },
  { label: 'Grant', value: 2 },
  { label: 'Guarantee Funds', value: 6 },
  { label: 'Paid Expense (non-grant)', value: 3 },
  { label: 'Pay-for-Performance Contract', value: 7 },
  { label: 'Provision of Debt/Loan Funds', value: 4 },
  { label: 'Other Investment Type', value: 5 },
];

const roles = [
  'Retired Entrepreneur',
  'Senior VP of Corporate Engagement',
  'Private Foundation President',
  'State Director of Commerce',
  'Investment Manager for Private Impact Investment Fund',
];

class DebriefInvestmentPrePost extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: this.props.item || null,
      group: '',
    };

    // Bindings
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.dispatch(gameActions.fetchGames(this.props.match.params.id));
    }
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      item: newProps.item,
    });
  }

  render() {
    const { group } = this.state;
    const { game } = this.props;

    const theGroup = group
      ? game.data.groups.find((g) => g._id === group)
      : null;
    const thePlayers = theGroup
      ? game.data.players.filter((p) =>
          theGroup.players.find((gp) => gp._id === p._id)
        )
      : game.data.players;

    // console.log({ theGroup, thePlayers });

    // Get players plays count
    const investmentTypes = [];
    const tacticsPerTypePre = [];
    const tacticsPerType = [];
    // Loop investments and get total tactics
    for (let i = 0; i < investments.length; ++i) {
      // PRE: Count the originals
      let thisTypeTacticsPre = 0;
      for (let j = 0; j < thePlayers.length; ++j) {
        let typeDros = [];
        thePlayers[j].originals.forEach((t) => {
          typeDros = typeDros.concat(
            t.dros.filter((d) => d.investment == investments[i].value)
          );
        });
        thisTypeTacticsPre += typeDros.reduce(function (acc, d) {
          return (
            acc +
            parseInt(d.y1 || 0) +
            parseInt(d.y2 || 0) +
            parseInt(d.y3 || 0) +
            parseInt(d.y4 || 0) +
            parseInt(d.y5 || 0) +
            parseInt(d.y6 || 0)
          );
        }, 0);
        if (j == thePlayers.length - 1)
          tacticsPerTypePre.push(thisTypeTacticsPre);
      }

      // POST: Count the strategies
      let thisTypeTacticsPost = 0;
      for (let j = 0; j < thePlayers.length; ++j) {
        if (thePlayers[j].submited_plan) {
          let typeDros = [];
          thePlayers[j].tactics.forEach((t) => {
            typeDros = typeDros.concat(
              t.dros.filter((d) => d.investment == investments[i].value)
            );
          });
          thisTypeTacticsPost += typeDros.reduce(function (acc, d) {
            return (
              acc +
              parseInt(d.y1 || 0) +
              parseInt(d.y2 || 0) +
              parseInt(d.y3 || 0) +
              parseInt(d.y4 || 0) +
              parseInt(d.y5 || 0) +
              parseInt(d.y6 || 0)
            );
          }, 0);
        }
        if (j == thePlayers.length - 1)
          tacticsPerType.push(thisTypeTacticsPost);
      }

      investmentTypes.push(investments[i].label);

      // // if there's tactics, add this to the data
      // if( thisTypeTacticsPre > 0 || thisTypeTacticsPost > 0 ){
      //     // Add the category
      //     investmentTypes.push(investments[i].label);
      // }
    }

    return (
      <div>
        <Header />

        <h2 className='mt-3 sec-title'>
          Investment Pre &amp; Post
          <small className='ml-3' style={{ fontSize: '.8rem' }}>
            <select
              className='form-control form-control-sm col-1 d-inline'
              value={group}
              onChange={(e) => this.setState({ group: e.target.value })}>
              <option value=''>All</option>
              {game.data.groups.map((g, gi) => (
                <option value={g._id}>{`Group #${gi + 1}`}</option>
              ))}
            </select>
          </small>
          <small className='float-right'>
            <DebriefMenu
              game_id={game.data._id}
              location={this.props.location}
            />
          </small>
        </h2>

        <div className='row'>
          <div className='col-12'>
            <HighchartsReact
              containerProps={{ style: { height: '100%', paddingTop: '20px' } }}
              highcharts={Highcharts}
              options={{
                chart: { type: 'column', height: 480 },
                credits: { enabled: false },
                title: { text: '' },
                xAxis: {
                  categories: investmentTypes,
                  labels: { style: { fontSize: '14px' } },
                },
                yAxis: {
                  allowDecimals: false,
                  min: 0,
                  title: { text: 'Total Tactics' },
                  labels: { style: { fontSize: '14px' } },
                },
                legend: {
                  style: {
                    fontWeight: 'bold',
                    color:
                      (Highcharts.theme && Highcharts.theme.textColor) ||
                      'gray',
                  },
                },
                series: [
                  {
                    name: 'Tactics Pre',
                    data: tacticsPerTypePre,
                  },
                  {
                    name: 'Tactics Post',
                    data: tacticsPerType,
                  },
                ],
              }}
            />
          </div>
        </div>
        <div className='alert alert-primary mt-2'>
          These charts should be utilized once groups have completed the
          collaboration phase.
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { player, game } = state;
  return {
    game,
    player,
  };
}

export default connect(mapStateToProps)(DebriefInvestmentPrePost);
