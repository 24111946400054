import React from 'react';
// import { Link } from 'react-router-dom';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { connect } from 'react-redux';

// Actions
import { gameActions } from '../../actions';

import Header from '../../components/Header';
import { DebriefMenu } from './debrief_menu';

// Staatic Roles
const roles = [
  'Retired Entrepreneur',
  'Senior VP of Corporate Engagement',
  'Private Foundation President',
  'State Director of Commerce',
  'Investment Manager for Private Impact Investment Fund',
];

class DebriefComposition extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: this.props.item || null,
      mode: 'group',
    };

    // Bindings
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.dispatch(gameActions.fetchGames(this.props.match.params.id));
    }
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      item: newProps.item,
    });
  }

  render() {
    const { game } = this.props;
    const { mode } = this.state;

    // Get players plays count
    let investmentTypes = [];
    let tacticsPerTypePre = [];
    let tacticsPerType = [];

    // X-Axis
    let xAxis;
    let iterable;
    if (mode === 'group') {
      xAxis = game.data.groups.map((g, gi) => 'Group ' + (gi + 1));
      iterable = game.data.groups;
    } else {
      xAxis = roles.map((g, gi) => g);
      iterable = roles;
    }

    // COLLABORATION
    const tacticsCollab = iterable.map((g) => 0);
    const tacticsNoCollab = iterable.map((g) => 0);
    // STAGE
    const tacticsIndividual = iterable.map((g) => 0);
    const tacticsGroup = iterable.map((g) => 0);

    for (let j = 0; j < iterable.length; ++j) {
      const iterablePlayers =
        mode === 'group'
          ? iterable[j].players
          : game.data.players.filter((p) => p.role == roles[j]);

      iterablePlayers.map((p) => {
        // console.log(p);
        // COLLABORATION
        p.tactics.forEach((t) => {
          const collab = t.dros.some((d) => d.author.email != p.email);
          if (collab) {
            tacticsCollab[j]++;
          } else {
            tacticsNoCollab[j]++;
          }
        });

        // STAGE
        const ploriginals = p.submited_plan
          ? game.data.players.find((pl) => pl._id === p._id).originals
          : p.tactics;
        const stageIndividual = ploriginals.length;
        const stageGroup = p.submited_plan
          ? p.tactics.filter((ft) => {
              return !ploriginals.find((ot) => ft._id == ot._id);
            }).length
          : 0;

        tacticsIndividual[j] += stageIndividual;
        tacticsGroup[j] += stageGroup;
      });
    }

    return (
      <div>
        <Header />

        <h2 className='mt-3 sec-title'>
          Composition
          <small className='ml-3' style={{ fontSize: '.8rem' }}>
            <select
              className='form-control form-control-sm col-1 d-inline'
              value={mode}
              onChange={(e) => this.setState({ mode: e.target.value })}>
              <option value='group'>By Group</option>
              <option value='role'>By Role</option>
            </select>
          </small>
          <small className='float-right'>
            <DebriefMenu
              game_id={game.data._id}
              location={this.props.location}
            />
          </small>
        </h2>

        <div>
          <HighchartsReact
            containerProps={{ style: { height: '100%', paddingTop: '20px' } }}
            highcharts={Highcharts}
            options={{
              chart: { type: 'column', height: 350 },
              credits: { enabled: false },
              title: { text: 'Final Plan Collaboration' },
              xAxis: {
                categories: xAxis,
                labels: { style: { fontSize: '14px' } },
              },
              yAxis: {
                allowDecimals: false,
                min: 0,
                title: { text: 'Total Tactics' },
                labels: { style: { fontSize: '14px' } },
              },
              legend: {
                style: {
                  fontWeight: 'bold',
                  //   color:
                  //     (Highcharts.theme && Highcharts.theme.textColor) || 'gray',
                },
              },
              plotOptions: {
                series: {
                  cursor: 'pointer',
                  events: {
                    // click: function (event) {
                    //   const firstPlayer =
                    //     game.data.groups[event.point.index].players[0];
                    //   const magicLink =
                    //     game_url +
                    //     '/feedback/?i=' +
                    //     firstPlayer.game_id +
                    //     '&e=' +
                    //     firstPlayer.email +
                    //     '&t=' +
                    //     firstPlayer.token;
                    //   // console.log('magicLink', magicLink);
                    //   const win = window.open(magicLink, '_blank');
                    //   win.focus();
                    // },
                  },
                },
              },
              series: [
                {
                  name: 'Collaborated',
                  data: tacticsCollab,
                },
                {
                  name: 'Non-Collaborated',
                  data: tacticsNoCollab,
                },
              ],
            }}
          />
          <HighchartsReact
            containerProps={{ style: { height: '100%', paddingTop: '20px' } }}
            highcharts={Highcharts}
            options={{
              chart: { type: 'column', height: 350 },
              credits: { enabled: false },
              title: { text: 'Final Plan Tactic Origins' },
              xAxis: {
                categories: xAxis,
                labels: { style: { fontSize: '14px' } },
              },
              yAxis: {
                allowDecimals: false,
                min: 0,
                title: { text: 'Total Tactics' },
                labels: { style: { fontSize: '14px' } },
              },
              legend: {
                style: {
                  fontWeight: 'bold',
                  //   color:
                  //     (Highcharts.theme && Highcharts.theme.textColor) || 'gray',
                },
              },
              series: [
                {
                  name: 'Individual Plan',
                  data: tacticsIndividual,
                },
                {
                  name: 'Group Plan',
                  data: tacticsGroup,
                },
              ],
            }}
          />
        </div>
        <div className='alert alert-primary mt-2'>
          These charts should be utilized once groups have completed the
          collaboration phase.
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { game } = state;
  return {
    game,
  };
}

export default connect(mapStateToProps)(DebriefComposition);
