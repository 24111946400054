import React from 'react';
// import { Link } from 'react-router-dom';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { connect } from 'react-redux';

// Actions
import { gameActions } from '../../actions';

import Header from '../../components/Header';
import { DebriefMenu } from './debrief_menu';

Highcharts.setOptions({
  lang: { decimalPoint: '.', thousandsSep: ',' },
});

const interventions = [
  { label: '4-Year College Scholarships', value: 14 },
  { label: 'After-School Programming', value: 5 },
  { label: 'Apprenticeship Program', value: 7 },
  { label: 'Business Start-up/Expansion for Job Creation', value: 18 },
  { label: 'Community College', value: 11 },
  { label: 'Company-Based Apprenticeships/Internships', value: 22 },
  { label: 'Criminal Justice Reentry Services', value: 15 },
  { label: 'Elementary/Middle School', value: 3 },
  { label: 'Foster Care Services', value: 13 },
  { label: 'GED/Bridge Program', value: 20 },
  { label: 'High School', value: 6 },
  { label: 'Hiring Incentives', value: 12 },
  { label: 'Housing Program or Support', value: 9 },
  { label: 'Income Support Program', value: 8 },
  { label: 'Internship/Apprenticeship Placement Program', value: 10 },
  { label: 'Mentoring Program', value: 1 },
  { label: 'Political Contributions', value: 17 },
  { label: 'Post-Secondary Training Program', value: 2 },
  { label: 'Preschool/Pre-K', value: 4 },
  { label: 'Public Advocacy/Lobbying', value: 16 },
  { label: 'Public Health Initiative', value: 23 },
  { label: 'Research/Evaluation', value: 19 },
  { label: 'Other', value: 21 },
];

const roles = [
  'Retired Entrepreneur',
  'Senior VP of Corporate Engagement',
  'Private Foundation President',
  'State Director of Commerce',
  'Investment Manager for Private Impact Investment Fund',
];

class DebriefInterventionPrePost extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: this.props.item || null,
      group: '',
    };

    // Bindings
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.dispatch(gameActions.fetchGames(this.props.match.params.id));
    }
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      item: newProps.item,
    });
  }

  render() {
    const { group } = this.state;
    const { game } = this.props;

    const theGroup = group
      ? game.data.groups.find((g) => g._id === group)
      : null;
    const thePlayers = theGroup
      ? game.data.players.filter((p) =>
          theGroup.players.find((gp) => gp._id === p._id)
        )
      : game.data.players;

    // CHART #1
    // Get players plays count
    const investmentTypes = [];
    const tacticsPerTypePre = [];
    const tacticsPerType = [];
    // Loop interventions and get total tactics
    for (let i = 0; i < interventions.length; ++i) {
      // PRE: Count the originals
      let thisTypeTacticsPre = 0;
      for (let j = 0; j < thePlayers.length; ++j) {
        const tacs = thePlayers[j].originals.filter(
          (t) => t.intervention == interventions[i].value
        );
        // console.log(tacs);
        thisTypeTacticsPre += tacs.length;
        if (j == thePlayers.length - 1)
          tacticsPerTypePre.push(thisTypeTacticsPre);
      }

      // POST: Count the strategies
      let thisTypeTacticsPost = 0;
      for (let j = 0; j < thePlayers.length; ++j) {
        const tacs = thePlayers[j].submited_plan
          ? thePlayers[j].tactics.filter(
              (t) => t.intervention == interventions[i].value
            )
          : [];
        // console.log(tacs);
        thisTypeTacticsPost += tacs.length;
        if (j == thePlayers.length - 1)
          tacticsPerType.push(thisTypeTacticsPost);
      }

      investmentTypes.push(interventions[i].label);

      // // if there's tactics, add this to the data
      // if( thisTypeTacticsPre > 0 || thisTypeTacticsPost > 0 ){
      //     // Add the category
      //     investmentTypes.push(interventions[i].label);
      // }
    }

    // CHART 2
    // Get players plays count
    const investmentTypes2 = [];
    const tacticsPerTypePre2 = [];
    const tacticsPerType2 = [];
    // Loop interventions and get total tactics
    for (let i = 0; i < interventions.length; ++i) {
      // PRE: Count the originals
      let thisTypeTacticsPre = 0;
      for (let j = 0; j < thePlayers.length; ++j) {
        const tacs = thePlayers[j].originals.filter(
          (t) => t.intervention == interventions[i].value
        );
        // thisTypeTacticsPre += tacs.length;
        thisTypeTacticsPre += tacs.reduce(function (acc, t) {
          return (
            acc +
            t.dros.reduce(function (dac, d) {
              return (
                dac +
                parseInt(d.y1 || 0) +
                parseInt(d.y2 || 0) +
                parseInt(d.y3 || 0) +
                parseInt(d.y4 || 0) +
                parseInt(d.y5 || 0) +
                parseInt(d.y6 || 0)
              );
            }, 0)
          );
        }, 0);
        if (j == thePlayers.length - 1)
          tacticsPerTypePre2.push(thisTypeTacticsPre);
      }

      // POST: Count the strategies
      let thisTypeTacticsPost = 0;
      for (let j = 0; j < thePlayers.length; ++j) {
        const tacs = thePlayers[j].submited_plan
          ? thePlayers[j].tactics.filter(
              (t) => t.intervention == interventions[i].value
            )
          : [];
        // thisTypeTacticsPost += tacs.length;
        thisTypeTacticsPost += tacs.reduce(function (acc, t) {
          return (
            acc +
            t.dros.reduce(function (dac, d) {
              return (
                dac +
                parseInt(d.y1 || 0) +
                parseInt(d.y2 || 0) +
                parseInt(d.y3 || 0) +
                parseInt(d.y4 || 0) +
                parseInt(d.y5 || 0) +
                parseInt(d.y6 || 0)
              );
            }, 0)
          );
        }, 0);
        if (j == thePlayers.length - 1)
          tacticsPerType2.push(thisTypeTacticsPost);
      }

      // console.log(interventions[i].label, thisTypeTacticsPre, thisTypeTacticsPost)

      // Add the category
      investmentTypes2.push(interventions[i].label);
    }

    return (
      <div>
        <Header />

        <h2 className='mt-3 sec-title'>
          Intervention Pre &amp; Post
          <small className='ml-3' style={{ fontSize: '.8rem' }}>
            <select
              className='form-control form-control-sm col-1 d-inline'
              value={group}
              onChange={(e) => this.setState({ group: e.target.value })}>
              <option value=''>All</option>
              {game.data.groups.map((g, gi) => (
                <option value={g._id}>{`Group #${gi + 1}`}</option>
              ))}
            </select>
          </small>
          <small className='float-right'>
            <DebriefMenu
              game_id={game.data._id}
              location={this.props.location}
            />
          </small>
        </h2>

        <div className='row'>
          <div className='col-12 bg-white'>
            <HighchartsReact
              containerProps={{ style: { height: '100%', paddingTop: '20px' } }}
              highcharts={Highcharts}
              options={{
                chart: { type: 'column', height: 450 },
                credits: { enabled: false },
                title: { text: 'Number of Tactics by Intervention Type' },
                xAxis: {
                  categories: investmentTypes,
                  labels: { style: { fontSize: '14px' } },
                },
                yAxis: {
                  allowDecimals: false,
                  min: 0,
                  title: { text: 'Total Tactics' },
                  labels: { style: { fontSize: '14px' } },
                },
                legend: {
                  style: {
                    fontWeight: 'bold',
                    color:
                      (Highcharts.theme && Highcharts.theme.textColor) ||
                      'gray',
                  },
                },
                series: [
                  {
                    name: 'Pre-Collaboration',
                    data: tacticsPerTypePre,
                  },
                  {
                    name: 'Post-Collaboration',
                    data: tacticsPerType,
                  },
                ],
              }}
            />
          </div>
          <div className='col-12 bg-white mt-4 mb-4'>
            <HighchartsReact
              containerProps={{ style: { height: '100%', paddingTop: '20px' } }}
              highcharts={Highcharts}
              options={{
                chart: { type: 'column', height: 450 },
                credits: { enabled: false },
                title: { text: 'Total Investment by Intervention Type' },
                xAxis: {
                  categories: investmentTypes,
                  labels: { style: { fontSize: '14px' } },
                },
                yAxis: {
                  allowDecimals: false,
                  min: 0,
                  title: { text: 'Total Investment ($)' },
                  labels: { style: { fontSize: '14px' } },
                },
                legend: {
                  style: {
                    fontWeight: 'bold',
                    color:
                      (Highcharts.theme && Highcharts.theme.textColor) ||
                      'gray',
                  },
                },
                tooltip: {
                  pointFormat: '{series.name}: <b>${point.y:,.0f}</b><br/>',
                },
                series: [
                  {
                    name: 'Pre-Collaboration',
                    data: tacticsPerTypePre2,
                  },
                  {
                    name: 'Post-Collaboration',
                    data: tacticsPerType2,
                  },
                ],
              }}
            />
          </div>
        </div>
        <div className='alert alert-primary mt-1'>
          These charts should be utilized once groups have completed the
          collaboration phase.
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { player, game } = state;
  return {
    game,
    player,
  };
}

export default connect(mapStateToProps)(DebriefInterventionPrePost);
