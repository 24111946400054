import React from 'react';
// import { Link } from 'react-router-dom';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { connect } from 'react-redux';

// Actions
import { gameActions } from '../../actions';

import Header from '../../components/Header';
import { DebriefMenu } from './debrief_menu';

const investments = [
  { label: 'Equity Investment', value: 1 },
  { label: 'Grant', value: 2 },
  { label: 'Guarantee Funds', value: 6 },
  { label: 'Paid Expense (non-grant)', value: 3 },
  { label: 'Pay-for-Performance Contract', value: 7 },
  { label: 'Provision of Debt/Loan Funds', value: 4 },
  { label: 'Other Investment Type', value: 5 },
];

const roles = [
  'Retired Entrepreneur',
  'Senior VP of Corporate Engagement',
  'Private Foundation President',
  'State Director of Commerce',
  'Investment Manager for Private Impact Investment Fund',
];

class DebriefInvestment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: this.props.item || null,
      roleFilter: '',
    };

    // Bindings
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.dispatch(gameActions.fetchGames(this.props.match.params.id));
    }
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      item: newProps.item,
    });
  }

  render() {
    const { roleFilter } = this.state;
    const { game } = this.props;

    // Get players plays count
    const investmentTypes = [];
    const tacticsPerType = [];
    const investmentPerType = [];
    // Loop investments and get total tactics
    for (let i = 0; i < investments.length; ++i) {
      // Add the category
      investmentTypes.push(investments[i].label);
      // Count the strategies
      let thisTypeTactics = 0;
      let thisTypeInvestment = 0;
      let thisTypeInvestmentDroMoney = 0;
      const filteredPlayers = game.data.players;
      for (let j = 0; j < filteredPlayers.length; ++j) {
        const tacs = filteredPlayers[j].tactics.filter((t) => {
          t.dros.forEach((d) => {
            if (d.investment == investments[i].value) {
              if (roleFilter.length == 0)
                thisTypeInvestmentDroMoney +=
                  Number(d.y1) +
                  Number(d.y2) +
                  Number(d.y3) +
                  Number(d.y4) +
                  Number(d.y5) +
                  Number(d.y6);
              else if (d.author.role == roleFilter)
                thisTypeInvestmentDroMoney +=
                  Number(d.y1) +
                  Number(d.y2) +
                  Number(d.y3) +
                  Number(d.y4) +
                  Number(d.y5) +
                  Number(d.y6);
            }
          });
          return t.dros.some(function (d) {
            // console.log(d.investment, investments[i].value)
            return d.investment == investments[i].value;
          });
        });

        thisTypeTactics += tacs.length;
        thisTypeInvestment += tacs.reduce(function (acct, t) {
          return (
            acct +
            t.dros.reduce(function (acc, d) {
              return (
                acc +
                Number(d.y1) +
                Number(d.y2) +
                Number(d.y3) +
                Number(d.y4) +
                Number(d.y5) +
                Number(d.y6)
              );
            }, 0)
          );
        }, 0);

        if (j == filteredPlayers.length - 1) {
          tacticsPerType[i] = thisTypeTactics;
          investmentPerType[i] = thisTypeInvestmentDroMoney;
        }
      }
    }

    // console.log(investmentTypes, tacticsPerType);
    console.log({ investmentPerType });

    return (
      <div>
        <Header />

        <h2 className='mt-3 sec-title'>
          Investment
          <small class='float-right'>
            <DebriefMenu
              game_id={game.data._id}
              location={this.props.location}
            />
          </small>
        </h2>

        <div className='row'>
          <div className='col-9'>
            <HighchartsReact
              containerProps={{ style: { height: '100%', paddingTop: '20px' } }}
              highcharts={Highcharts}
              options={{
                chart: { type: 'bar', height: 500 },
                credits: { enabled: false },
                title: { text: '' },
                xAxis: {
                  categories: investmentTypes,
                  labels: { style: { fontSize: '14px' } },
                },
                yAxis: [
                  {
                    allowDecimals: false,
                    min: 0,
                    title: { text: 'Total Investment ($)' },
                    opposite: false,
                  },
                ],
                legend: {
                  enabled: true,
                },
                series: [
                  {
                    name: 'Total Investment',
                    data: investmentPerType,
                    yAxis: 0,
                    tooltip: {
                      pointFormatter: function () {
                        return (
                          '<b>Total: $' +
                          Highcharts.numberFormat(this.y, 0, '.', ',') +
                          '</b>'
                        );
                      },
                    },
                  },
                ],
              }}
            />
          </div>
          <div className='col-3 pt-3'>
            <button
              onClick={() => this.setState({ roleFilter: '' })}
              className={`pt-3 pb-3 btn btn-block btn-${
                !roleFilter ? '' : 'outline-'
              }primary`}
              key={`role-all`}>
              Overall
            </button>
            {roles.map((r, ri) => {
              return (
                <button
                  onClick={() => this.setState({ roleFilter: r })}
                  className={`pt-3 pb-3 btn btn-block btn-${
                    r === roleFilter ? '' : 'outline-'
                  }primary`}
                  key={`role-${ri}`}>
                  {r}
                </button>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { player, game } = state;
  return {
    game,
    player,
  };
}

export default connect(mapStateToProps)(DebriefInvestment);
