import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { useJsonToCsv } from 'react-json-csv';

// Utils
import { validateEmail } from '../../helpers/utils';

// Actions
import { gameActions, modalActions } from '../../actions';

import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Header from '../../components/Header';
import List from './list';
import AddPlayer from './add_player';

// CSV HOC
const withJsonToCsv = (Component) => (props) => {
  const { saveAsCsv } = useJsonToCsv();
  return <Component {...props} saveAsCsv={saveAsCsv} />;
};

// Logic
const investments = [
  { label: 'Equity Investment', value: 1 },
  { label: 'Grant', value: 2 },
  { label: 'Guarantee Funds', value: 6 },
  { label: 'Paid Expense (non-grant)', value: 3 },
  { label: 'Pay-for-Performance Contract', value: 7 },
  { label: 'Provision of Debt/Loan Funds', value: 4 },
  { label: 'Other Investment Type', value: 5 },
];

const interventions = [
  { label: '4-Year College Scholarships', value: 14 },
  { label: 'After-School Programming', value: 5 },
  { label: 'Apprenticeship Program', value: 7 },
  { label: 'Business Start-up/Expansion for Job Creation', value: 18 },
  { label: 'Community College', value: 11 },
  { label: 'Company-Based Apprenticeships/Internships', value: 22 },
  { label: 'Criminal Justice Reentry Services', value: 15 },
  { label: 'Elementary/Middle School', value: 3 },
  { label: 'Foster Care Services', value: 13 },
  { label: 'GED/Bridge Program', value: 20 },
  { label: 'High School', value: 6 },
  { label: 'Hiring Incentives', value: 12 },
  { label: 'Housing Program or Support', value: 9 },
  { label: 'Income Support Program', value: 8 },
  { label: 'Internship/Apprenticeship Placement Program', value: 10 },
  { label: 'Mentoring Program', value: 1 },
  { label: 'Political Contributions', value: 17 },
  { label: 'Post-Secondary Training Program', value: 2 },
  { label: 'Preschool/Pre-K', value: 4 },
  { label: 'Public Advocacy/Lobbying', value: 16 },
  { label: 'Public Health Initiative', value: 23 },
  { label: 'Research/Evaluation', value: 19 },
  { label: 'Other', value: 21 },
];

class Players extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: this.props.item || null,
      pair_modal: false,
      new_players: [],
    };

    // Bindings
    this.addPairModal = this.addPairModal.bind(this);
    this.handleNewPlayers = this.handleNewPlayers.bind(this);
    this.submitNewPlayers = this.submitNewPlayers.bind(this);
  }

  // componentDidMount() {
  //   if (this.props.match.params.id) {
  //     this.props.dispatch(gameActions.fetchGames(this.props.match.params.id));
  //   }
  // }

  componentWillReceiveProps(newProps) {
    this.setState({
      item: newProps.item,
    });
  }

  addPairModal() {
    this.setState({ pair_modal: !this.state.pair_modal, new_players: [] });
  }

  handleNewPlayers(newPlayers) {
    this.setState({ new_players: newPlayers });
  }

  submitNewPlayers() {
    const { game } = this.props;
    this.props.dispatch(
      gameActions.addPlayers(game.data._id, this.state.new_players)
    );
    this.addPairModal();
    this.setState({ new_players: [] });
  }

  individualSwitchTo(player_id, boo) {
    const { dispatch, game } = this.props;
    dispatch(
      modalActions.open({
        title: 'Please Confirm',
        body: "You are about change this player's individual plan submission status.",
        buttons: [
          <button
            className='btn btn-outline-secondary'
            onClick={() => dispatch(modalActions.close())}>
            Cancel
          </button>,
          <button
            className='btn btn-outline-primary'
            onClick={() => {
              dispatch(
                gameActions.setPlayerStatus(game.data._id, player_id, boo)
              );
              dispatch(modalActions.close());
            }}>
            Set to {boo ? 'Submitted' : 'Not Submitted'}
          </button>,
        ],
      })
    );
  }

  groupSwitchTo(group_id, boo) {
    const { dispatch, game } = this.props;
    dispatch(
      modalActions.open({
        title: 'Please Confirm',
        body: "You are about change this player's group plan submission status.",
        buttons: [
          <button
            className='btn btn-outline-secondary'
            onClick={() => dispatch(modalActions.close())}>
            Cancel
          </button>,
          <button
            className='btn btn-outline-primary'
            onClick={() => {
              dispatch(
                gameActions.setGroupStatus(game.data._id, group_id, boo)
              );
              dispatch(modalActions.close());
            }}>
            Set to {boo ? 'Submitted' : 'Not Submitted'}
          </button>,
        ],
      })
    );
  }

  csvPlayer(player_idx) {
    const { game, saveAsCsv } = this.props;
    const player = game.data.players[player_idx - 1];

    // Define the fields
    var fields = {
      strategy: 'Strategy',
      name: 'Name',
      lastname: 'Lastname',
      email: 'Email',
      role: 'Role',
      tactic: 'Tactic',
      details_title: 'Details',
      investment: 'Investment',
      intervention: 'Intervention',
      target: 'Target',
      size: 'Size',
      deployments: 'Deployments',
      y1: 'Year 1',
      y2: 'Year 2',
      y3: 'Year 3',
      y4: 'Year 4',
      y5: 'Year 5',
      y6: 'Year 6',
      returns: 'Returns',
      r1: 'Returns Year 1',
      r2: 'Returns Year 2',
      r3: 'Returns Year 3',
      r4: 'Returns Year 4',
      r5: 'Returns Year 5',
      r6: 'Returns Year 6',
      outcomes: 'Outcomes',
    };
    var data = [];

    console.log(`Creating CSV for player ${player_idx}: ${player?.email}`);

    if (player) {
      const stageTactics =
        player?.submited_plan === true ? player.originals : player.tactics;
      if (stageTactics.length > 0) {
        stageTactics.forEach((t, ti) => {
          if (t.dros.length > 0) {
            t.dros.forEach((d, di) => {
              var inv = investments.find((i) => i.value == d.investment);
              inv = inv ? inv.label : '';
              var inter = interventions.find((i) => i.value == t.intervention);
              inter = inter ? inter.label : '';

              data.push({
                strategy: `"${player.strategy
                  .replace(/"/g, '""')
                  .replace(/(<([^>]+)>)/gi, '')}"`,
                name: player.name,
                lastname: player.lastname,
                email: player.email,
                role: player.role,
                tactic: `"${t.title}"`,
                details_title: `"${t.details
                  .replace(/"/g, '""')
                  .replace(/(<([^>]+)>)/gi, '')}"`,
                investment: inv,
                intervention: inter,
                target: `"${d.target}"`,
                size: d.size,
                dro: di + 1,
                deployments: `"${d.deployments
                  .replace(/"/g, '""')
                  .replace(/(<([^>]+)>)/gi, '')}"`,
                y1: d.y1,
                y2: d.y2,
                y3: d.y3,
                y4: d.y4,
                y5: d.y5,
                y6: d.y6,
                returns: `"${d.returns
                  .replace(/"/g, '""')
                  .replace(/(<([^>]+)>)/gi, '')}"`,
                r1: d.r1,
                r2: d.r2,
                r3: d.r3,
                r4: d.r4,
                r5: d.r5,
                r6: d.r6,
                outcomes: `"${d.outcomes
                  .replace(/"/g, '""')
                  .replace(/(<([^>]+)>)/gi, '')}"`,
              });
            });

            if (ti == stageTactics.length - 1) {
              // CSV
              // console.log(data, 1);
              saveAsCsv({
                data,
                fields,
                filename: `SOCIM_${game.data._id}_Player_${player.email}.csv`,
              });
            }
          } else {
            if (ti == stageTactics.length - 1) {
              // CSV
              // console.log(data, 2);
              saveAsCsv({
                data,
                fields,
                filename: `SOCIM_${game.data._id}_Player_${player.email}.csv`,
              });
            }
          }
        });
      } else {
        saveAsCsv({
          data,
          fields,
          filename: `SOCIM_${game.data._id}_Player_${player.email}.csv`,
        });
      }
    }
  }

  csvGroup(group_idx) {
    const { game, saveAsCsv } = this.props;

    // Define the fields
    var fields = {
      group: 'Group',
      strategy: 'Strategy',
      name: 'Tactic Author: Name',
      lastname: 'Tactic Author: Lastname',
      email: 'Tactic Author: Email',
      role: 'Tactic Author: Role',
      tactic: 'Tactic',
      details_title: 'Details',

      dep_name: 'Deployment Author: Name',
      dep_lastname: 'Deployment Author: Lastname',
      dep_email: 'Deployment Author: Email',
      dep_role: 'Deployment Author: Role',

      investment: 'Investment',
      intervention: 'Intervention',
      target: 'Target',
      size: 'Size',
      deployments: 'Deployments',
      y1: 'Year 1',
      y2: 'Year 2',
      y3: 'Year 3',
      y4: 'Year 4',
      y5: 'Year 5',
      y6: 'Year 6',
      returns: 'Returns',
      r1: 'Returns Year 1',
      r2: 'Returns Year 2',
      r3: 'Returns Year 3',
      r4: 'Returns Year 4',
      r5: 'Returns Year 5',
      r6: 'Returns Year 6',
      outcomes: 'Outcumes',
    };
    var data = [];

    var theGroup = game.data.groups[group_idx - 1];
    theGroup.players.forEach((player, pi) => {
      if (player.tactics.length > 0) {
        player.tactics.forEach((t, ti) => {
          if (t.dros.length > 0) {
            t.dros.forEach((d, di) => {
              var inv = investments.find((i) => i.value == d.investment);
              inv = inv ? inv.label : '';
              var inter = interventions.find((i) => i.value == t.intervention);
              inter = inter ? inter.label : '';
              // Get this dro author
              const droAuthor = d?.author;

              data.push({
                group: group_idx,
                strategy: `"${theGroup.strategy
                  .replace(/"/g, '""')
                  .replace(/(<([^>]+)>)/gi, '')}"`,
                name: player?.name,
                lastname: player?.lastname,
                email: player?.email,
                role: player?.role,
                tactic: `"${t.title}"`,
                details_title: `"${t.details
                  .replace(/"/g, '""')
                  .replace(/(<([^>]+)>)/gi, '')}"`,

                dep_name: droAuthor?.name,
                dep_lastname: droAuthor?.lastname,
                dep_email: droAuthor?.email,
                dep_role: droAuthor?.role,

                investment: inv,
                intervention: inter,
                target: `"${d.target}"`,
                size: d.size,
                dro: di + 1,
                deployments: `"${d.deployments
                  .replace(/"/g, '""')
                  .replace(/(<([^>]+)>)/gi, '')}"`,
                y1: d.y1,
                y2: d.y2,
                y3: d.y3,
                y4: d.y4,
                y5: d.y5,
                y6: d.y6,
                returns: `"${d.returns
                  .replace(/"/g, '""')
                  .replace(/(<([^>]+)>)/gi, '')}"`,
                r1: d.r1,
                r2: d.r2,
                r3: d.r3,
                r4: d.r4,
                r5: d.r5,
                r6: d.r6,
                outcomes: `"${d.outcomes
                  .replace(/"/g, '""')
                  .replace(/(<([^>]+)>)/gi, '')}"`,
              });
            });

            if (
              pi == theGroup.players.length - 1 &&
              ti == player.tactics.length - 1
            ) {
              // CSV
              // console.log(data, 1);
              saveAsCsv({
                data,
                fields,
                filename: `SOCIM_${game.data._id}_Group_${group_idx}.csv`,
              });
            }
          } else {
            if (
              pi == theGroup.players.length - 1 &&
              ti == player.tactics.length - 1
            ) {
              // CSV
              // console.log(data, 2);
              saveAsCsv({
                data,
                fields,
                filename: `SOCIM_${game.data._id}_Group_${group_idx}.csv`,
              });
            }
          }
        });
      } else {
        if (pi == theGroup.players.length - 1) {
          // CSV
          // console.log(data, 3);
          saveAsCsv({
            data,
            fields,
            filename: `SOCIM_${game.data._id}_Group_${group_idx}.csv`,
          });
        }
      }
    });
  }

  render() {
    const { user, game } = this.props;

    let players = game.data.players || [];
    const groups = game.data.groups || [];

    // const zephyrRole = p.players.find( pl => pl.rindex === 1 )
    // const fedeRole   = p.players.find( pl => pl.rindex === 0 )

    players = players.map((p, pi) => {
      const gindex = groups.findIndex((g) =>
        g.players.find((gp) => gp._id === p._id)
      );

      const ml =
        process.env.REACT_APP_GAME_URL +
        '?i=' +
        p.game_id +
        '&e=' +
        p.email +
        '&t=' +
        p.token +
        '&k=1';

      return {
        group: <small>{1 + gindex}</small>,
        name: <small>{p.name}</small>,
        lastname: <small>{p.lastname}</small>,
        email: <small>{p.email}</small>,
        role: <small>{p.role}</small>,
        individual_submit: (
          <button
            disabled={!p.submited_plan}
            className={`btn btn-sm btn-block ${
              p.submited_plan ? 'btn-outline-primary' : 'btn-outline-secondary'
            }`}
            onClick={() => this.individualSwitchTo(p._id, !p.submited_plan)}>
            {p.submited_plan ? (
              <>
                Submitted
                <br />
                <small>(Reopen)</small>
              </>
            ) : (
              <>
                Not
                <br />
                Submitted
              </>
            )}
          </button>
        ),
        group_submit: (
          <button
            className={`btn btn-sm btn-block ${
              groups[gindex]?.submited_plan
                ? 'btn-outline-primary'
                : 'btn-outline-secondary'
            }`}
            disabled={!groups[gindex]?.submited_plan}
            onClick={() =>
              this.groupSwitchTo(
                groups[gindex]._id,
                !groups[gindex].submited_plan
              )
            }>
            {groups[gindex]?.submited_plan ? (
              <>
                Submitted
                <br />
                <small>(Reopen)</small>
              </>
            ) : (
              <>
                Not
                <br />
                Submitted
              </>
            )}
          </button>
        ),
        csv: (
          <div>
            <button
              className='btn btn-sm btn-link'
              onClick={() => this.csvPlayer(pi + 1)}>
              Individual
            </button>
            <hr className='mt-1 mb-1' />
            <button
              className='btn btn-sm btn-link'
              onClick={() => this.csvGroup(gindex + 1)}>
              Group
            </button>
          </div>
        ),
        magiclink:
          user.from !== 'lti-hbp' ? (
            <a href={ml} target='_blank' rel='noreferrer'>
              <code style={{ maxWidth: '100px' }}>
                <small>{ml}</small>
              </code>
            </a>
          ) : (
            'N/A'
          ),
      };
    });

    // Validate that all the players have a valid email
    const validNewPlayers =
      this.state.new_players.length === 0
        ? false
        : this.state.new_players.every((pl) => {
            return validateEmail(pl.email);
          });

    // Validate if there are repeated players
    const repeatedPlayers = this.state.new_players.filter((pl, pli) => {
      return (
        game.data.players.find((p) => p.email === pl.email) ||
        this.state.new_players.find(
          (p, pi) => p.email === pl.email && pli !== pi
        )
      );
    });

    return (
      <div>
        <Header />

        <h2 className='mt-3 sec-title'>
          Player Management{' '}
          <small
            className='badge badge-pill badge-success'
            style={{
              fontSize: '12px',
              padding: '5px 8px',
              verticalAlign: 'middle',
            }}>
            {players.length} players
          </small>
          <button
            className='btn btn-outline-primary ml-3 float-right'
            onClick={(e) => window.location.reload()}>
            <FontAwesomeIcon icon='redo-alt' />
          </button>
          {user.from !== 'lti-hbp' && (
            <small className='float-right'>
              <button
                className='btn btn-outline-primary'
                onClick={this.addPairModal}>
                Add Player
              </button>
            </small>
          )}
        </h2>
        <List
          labels={[
            'Group',
            'Name',
            'Surname',
            'Email',
            'Role',
            // 'Status',
            'Individual',
            'Group',
            'Download',
            'MagicLink',
          ]}
          fields={[
            'group',
            'name',
            'lastname',
            'email',
            'role',
            // 'status',
            'individual_submit',
            'group_submit',
            'csv',
            'magiclink',
          ]}
          data={players}
        />

        <Modal
          isOpen={this.state.pair_modal}
          toggle={this.addPairModal}
          centered={true}
          size={'lg'}>
          <ModalHeader>Add Player</ModalHeader>
          <ModalBody className='text-center'>
            <p>
              Enter the player information and click the <b>"Add"</b> button.
            </p>
            <AddPlayer onChange={this.handleNewPlayers} />
            {repeatedPlayers.length > 0 && (
              <div className='alert alert-danger mt-4'>
                The following players are already in this session:{' '}
                <b>{repeatedPlayers.map((p) => p.email).join(', ')}</b>
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <button className='btn btn-light' onClick={this.addPairModal}>
              Cancel
            </button>
            <button
              className='btn btn-outline-primary'
              onClick={this.submitNewPlayers}
              disabled={!validNewPlayers || repeatedPlayers.length > 0}>
              Add
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { player, game, authentication } = state;
  const { user } = authentication;
  return {
    user,
    game,
    player,
  };
}

// Wraping the component with the CSV HOC
const PlayersWithCsv = withJsonToCsv(Players);

const connectedPlayersPage = connect(mapStateToProps)(PlayersWithCsv);
export { connectedPlayersPage as GamePlayers };
