import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';

// Utils
import { validateEmail } from '../../helpers/utils';

// Actions
import { gameActions } from '../../actions';

import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Header from '../../components/Header';
import Reopen from '../../components/Reopen';
import List from './list';
import AddPlayer from './add_player';

const statusLabels = {
  init: 'Ongoing',
  deal: 'Deal',
  nodeal: 'Abandoned',
};

class Download extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: this.props.item || null,
      pair_modal: false,
      new_players: [],
    };

    // Bindings
    this.addPairModal = this.addPairModal.bind(this);
    this.handleNewDownload = this.handleNewDownload.bind(this);
    this.submitNewDownload = this.submitNewDownload.bind(this);
  }

  componentDidMount() {
    if (this.props.match.params.id) {
      this.props.dispatch(gameActions.fetchGames(this.props.match.params.id));
    }
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      item: newProps.item,
    });
  }

  addPairModal() {
    this.setState({ pair_modal: !this.state.pair_modal, new_players: [] });
  }

  handleNewDownload(newDownload) {
    this.setState({ new_players: newDownload });
  }

  submitNewDownload() {
    const { game } = this.props;
    this.props.dispatch(
      gameActions.addDownload(game.data._id, this.state.new_players)
    );
    this.addPairModal();
    this.setState({ new_players: [] });
  }

  render() {
    const { user, game } = this.props;

    let players = game.data.players || [];
    const groups = game.data.groups || [];

    // const zephyrRole = p.players.find( pl => pl.rindex === 1 )
    // const fedeRole   = p.players.find( pl => pl.rindex === 0 )

    players = players.map((p, pi) => {
      const gindex = groups.findIndex((g) =>
        g.players.find((gp) => gp._id === p._id)
      );
      p.group = 1 + gindex;
      p.count = p.results.length;
      p.role = p.role;
      p.status = groups[gindex] ? (
        <>
          <span>{statusLabels[groups[gindex].status]}</span>
          {groups[gindex].status !== 'init' && (
            <>
              <Reopen game={game.data._id} group={groups[gindex]._id} />
            </>
          )}
        </>
      ) : (
        ''
      );
      p.download = 'Download';
      return p;
    });

    // Validate that all the players have a valid email
    const validNewDownload =
      this.state.new_players.length === 0
        ? false
        : this.state.new_players.every((pl) => {
            return validateEmail(pl.email);
          });
    // Validate if there are repeated players
    const repeatedDownload = this.state.new_players.filter((pl, pli) => {
      return (
        players.find((p) => p.email === pl.email) ||
        this.state.new_players.find(
          (p, pi) => p.email === pl.email && pli !== pi
        )
      );
    });

    return (
      <div>
        <Header />

        <h2 className='mt-3 sec-title'>
          Download
          <button
            className='btn btn-outline-primary ml-3 float-right'
            onClick={(e) => window.location.reload()}>
            <FontAwesomeIcon icon='redo-alt' />
          </button>
          {user.from !== 'lti-hbp' && (
            <small className='float-right'>
              <button
                className='btn btn-outline-primary'
                onClick={this.addPairModal}>
                Add Player
              </button>
            </small>
          )}
        </h2>
        <List
          labels={['Group', 'Name', 'Lastname', 'Email', 'Role', 'Download']}
          fields={['group', 'name', 'lastname', 'email', 'role', 'download']}
          data={players}
        />

        <Modal
          isOpen={this.state.pair_modal}
          toggle={this.addPairModal}
          centered={true}
          size={'lg'}>
          <ModalHeader>Add Player</ModalHeader>
          <ModalBody className='text-center'>
            <p>
              Enter the player information and click the <b>"Add"</b> button.
            </p>
            <AddPlayer
              onChange={this.handleNewDownload}
              roles={["Federico's", 'Zephyr']}
            />
            {repeatedDownload.length > 0 && (
              <div className='alert alert-danger mt-4'>
                The following players are already in this session:{' '}
                <b>{repeatedDownload.map((p) => p.email).join(', ')}</b>
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <button className='btn btn-light' onClick={this.addPairModal}>
              Cancel
            </button>
            <button
              className='btn btn-outline-primary'
              onClick={this.submitNewDownload}
              disabled={!validNewDownload || repeatedDownload.length > 0}>
              Add
            </button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { player, game, authentication } = state;
  const { user } = authentication;
  return {
    user,
    game,
    player,
  };
}

export default connect(mapStateToProps)(Download);
